.testimonial__header {
    padding-top: 5rem;
}
.swiper-pagination-clickable .swiper-pagination-bullet {
    background: var(--color-primary);
    position: relative;
    
}


.testimonials__container {
    width: 60%;

}

.swiper-button-next, .swiper-button-prev {
    color: var(--color-white);
}

.ref__avatar {
    width: 5.6rem;
    height: 5.6rem;
    aspect-ratio: 1/1;
    overflow: hidden;
    border-radius: 50%;
    margin: 0rem auto 1rem;
    border: 0.3rem solid var(--color-primary);
    background-color: var(--color-primary);
    
}

.testimonial {
    background: var(--color-bg-variant);
    text-align: center;
    padding: 1rem;
    user-select: none;
    border-radius: 2rem;
    border: 5px solid var(--color-primary-variant);
    height: 80%;
    margin-bottom: 3rem;
}

.ref__review {
    color: var(--color-light);
    font-weight: 300;
    display: block;
    width: 80%;
    margin: 0.6rem auto 0;
}
.ref__name {
    color: var(--color-primary);
    font-size: 1.2rem;
}
.ref__social {
    font-size: 1.5rem;
}

small {
    padding-bottom: 1rem;
}

.ref__title {
    font-size: 1.1rem;
}

/*for medium devices like tablets*/

@media screen and (max-width:1080px) {
   .testimonials__container {
    width: 80%;
   }
}
   

  /*for small devices like phones*/
  
  @media screen and (max-width:600px) {
    .testimonials__container {
        width: var(--container-width-sm);
       }
    .testimonial__header {
        padding-top: 6rem;
    }

    .ref__review {
        width: var(--container-width-sm);
    }

  }