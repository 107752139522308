.experience__header{
   padding-top: 5rem;
}

.experience__container {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 2rem;
}

.experience__container > div {
    background: var(--color-bg-variant);
    padding: 1.6rem 2rem;
    border-radius: 2rem;
    border: 5px solid transparent;
    border-color: var(--color-primary)
}

.experience__container > div:hover {
    background: var(--color-light);
    background-color: rgba(252, 249, 249, 0.3);
    border-color: var(--color-primary-variant);
    transition: var(--transition);
    
}

.experience__container > div h3 {
    text-align: center;
    margin-bottom: 2rem;
    color: var(--color-white);
}

.experience__content {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    row-gap: 2rem;
    
}


.experience__details {
    display: inline-flex;
    gap: 1rem;
    justify-content: center;
    color: var(--color-light);
    
}

.experience__details-icon {
    margin-top: 3px;
    color: var(--color-primary);
    font-size: 1.4rem;
    
}

.experience__icon {
    font-size: 1.8rem;
    bottom: 1%;
    color: var(--color-primary);
    text-align: center;

}

.experience__web {
    text-align: center;
}


/*for medium devices like tablets*/

@media screen and (max-width:1080px) {
   .experience__container {
    grid-template-columns: 1fr;
   }

   .experience__container > div {
    width: 80%;
    padding: 2rem;
    margin: 0 auto;
   }
   .experience__content {
    padding: 1rem;
   }
   
}
  /*for small devices like phones*/
  
  @media screen and (max-width:600px) {
    .experience__container {
        gap: 1rem;
    }
    .experience__container > div {
        width: 100%;
        padding: 2rem 0rem;
        margin: 0 auto;
       }

    .experience__header{
        padding-top: 6rem;
     }

  }