header {
    height: 39rem;
    margin-top: 4rem;
    
}

.header__container {
    text-align: center;
    height: 100%;
    position: relative;
    
}

.cta {
    margin-top: 2.5rem;
    display: flex;
    gap: 1.2rem;
    justify-content: center;

}

.header__socials {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 1.2rem;
    position: absolute;
    left: 50%;
    transform: translate(-50%);
    font-size: 2rem;
    margin-top: 3rem;
}

.header__socials::after{
    content: '';
    width: 2rem;
    height: 1px;
    background: var(--color-primary);
}
.header__socials::before{
    content: '';
    width: 2rem;
    height: 1px;
    background: var(--color-primary);
}
.me {
    display: block;
    margin: 0 auto;
    width: 18rem;
    justify-content: center;
    align-items: center;
    margin-top: 4rem;
    
    
    
    
}

.meImg {
    border-radius: 7rem 7rem 3rem 3rem ;
    
}



.opensea {
    height: 2.2rem;
    width: 2.2rem;
    filter: invert(40%) sepia(110%) saturate(700%) hue-rotate(180deg) brightness(93%) contrast(110%);
    margin-bottom: 7px;
    
    
    
}
.opensea:hover {
    filter: invert(0%) sepia(0%) saturate(1%) hue-rotate(289deg) brightness(106%) contrast(101%);
    transition: all 300ms linear;
}


/*for medium devices like tablets*/

@media screen and (max-width:1080px) {
    header {
        
        margin-top: 6rem;
        
    }
}
   

  /*for small devices like phones*/
  
  @media screen and (max-width:600px) {
    header {
        
    }

  }
  