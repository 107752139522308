footer {
    background: rgba(250, 248, 248, 0.45);
    padding: 1rem 0;
    text-align: center;
    font-size: 1.1rem;
    margin-top: 7rem;
    border-radius: 0.2rem;
}

footer h3 {
    color: var(--color-bg);
}